import { io } from "socket.io-client";
import CryptoJS from "crypto-js";
import parser from "./parser";

const SERVER = process.env.REACT_APP_BASE_URL;

// console.log(SERVER);
export const UseSocketFunction = () => {
  const authenticated = localStorage.getItem("token");
  const decryptTokenFunc = (tok) => {
    const encryptedToken = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedToken;
  };

  const auth = decryptTokenFunc(authenticated);
  // console.log(auth);

  const socket = io(SERVER, {
    withCredentials: true,
    cors: { origin: "*" },
    extraHeaders: {
      auth: authenticated ? decryptTokenFunc(authenticated) : "",
    },
    path: "/v1/socket.io",
    // parser,
    transports: ["websocket"],
    auth: async (cb) => {
      cb({
        auth: decryptTokenFunc(authenticated),
      });
    },
    // secure: true,
  });

  return socket;
  // return null;
};
  