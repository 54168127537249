import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useRef, useState } from "react";
import "../../../../styles/dashboard/pos/AddAlertRecievers.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { editTerminalLabelInfo } from "../../../../redux/pos";
import BadgeComponent from "../../../../components/fragments/badge/BadgeComponent";
import { removeSpace } from "../../../../utils/helper/Helper";

const GenerateStreamKeyModal = ({ visible, onClose, onFinish }) => {
  const { user } = useSelector((state) => state.user);
  const { loadingSettlement, terminalInfoSingle } = useSelector(
    (state) => state.pos
  );
  const handleClose = () => {
    onClose && onClose();
    setDetails((prev) => {
      return { ...prev, label: "" };
    });

    setCompletePin(false);
  };
  const dispatch = useDispatch();

  const [completePin, setCompletePin] = useState(false);
  const [details, setDetails] = useState({
    two_factor_token: "",
    label: "",
    stream_key: "",
    enable_history: false,
    enable_date: false,
    date: "",
  });
  const pinRefVal = useRef();
  // const location =

  useEffect(() => {
    setCompletePin(false);
    if (Object?.keys(terminalInfoSingle)?.length > 0 && visible) {
      setDetails((prev) => {
        return { ...prev, label: terminalInfoSingle?.tid_label };
      });
    }
    // pinRefVal.current.forEach((input) => (input.value = ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    const newObj = { ...details, [name]: value };
    setDetails(newObj);
  };

  const handleSubmit = async () => {
    const obj = {
      tid: terminalInfoSingle?.tid,
      new_label_name: details?.label,
    };
    // console.log(obj);

    const data = await dispatch(editTerminalLabelInfo(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
      handleClose();
    }
  };

  function generateAccountNumber() {
    const key = Array.from({ length: 6 }, () =>
      Math.floor(Math.random() * 6)
    ).join("");
    setDetails((prev) => {
      return {
        ...prev,
        stream_key: key,
      };
    });
  }

  const settingList = [
    {
      label: "Enable Transaction History",
      active: details?.enable_history,
      name: "enable_history",
    },
    {
      label: details?.date
        ? `Expires on ${details?.date ? formatDate(details?.date) : ""}`
        : `Set Expiry Date`,
      active: details?.enable_date,
      name: "enable_date",
    },
  ];

  const handleDisable = () => {
    // let val;
    if (details?.enable_date) {
      const data =
        !details?.stream_key ||
        removeSpace(String(details?.stream_key))?.length < 6 ||
        !details?.date;
      return data;
    } else {
      const data =
        !details?.stream_key ||
        removeSpace(String(details?.stream_key))?.length < 6;
      return data;
    }
    // return val;
  };

  function getDateTimeObject(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return {
      date: `${year}-${month}-${day}`, // YYYY-MM-DD
      time: `${hours}:${minutes}:${seconds}`, // HH:mm:ss
    };
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format. Expected YYYY-MM-DD.");
    }

    const options = {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const [weekday, month, day, year] = formattedDate.split(" ");

    return `${weekday?.replace(",", "")} ${day?.replace(
      ",",
      ""
    )}, ${month}, ${year}`;
  }

  return (
    <>
      <RavenModal
        onClose={handleClose}
        btnLabel="Generate Stream Key"
        btnColor="black-light"
        visble={visible}
        disabled={handleDisable()}
        // onBtnClick={handleSubmit}
        loading={loadingSettlement}
      >
        <div
          // onClick={() => {
          //   console.log(terminalInfoSingle);
          // }}
          className="edit-single-terminal-label-wrapper-index-box"
          style={{ paddingBottom: "3rem" }}
        >
          <div className="title-text-box border-theme-bottom">
            <p className="title">Generate Stream Key</p>
            <p className="text grey-white-color">
              Use the stream key to manage your transactions and your inflows.
            </p>
          </div>
          {/* form box start */}
          <div className="form-box">
            {/* input field start */}
            <div className="stream-number-input-box">
              <RavenInputField
                type="account-number"
                color="black-light"
                className="input"
                placeholder="000000"
                value={details?.stream_key}
                name={`stream_key`}
                onChange={handleChange}
                maxLength={6}
              />
            </div>
            {/* input field end */}
            {/* down label box start */}
            <div className="down-label-generate-box">
              <p className="label-down grey-white-color">
                Enter a 6 digit code or{" "}
              </p>
              <BadgeComponent
                onClick={generateAccountNumber}
                className={`pending badge`}
                text={`Auto Generate Key`}
              />
            </div>
            {/* down label box end */}
            {/* list box start */}
            <div className="list-box-to-set">
              {settingList?.map((chi, idx) => {
                return (
                  <div key={idx} className="label-check-box">
                    <RavenCheckBox
                      color="black-light"
                      checked={chi?.active}
                      onChange={() => {
                        if (
                          chi?.name === "enable_date" &&
                          !details?.enable_date
                        ) {
                          setDetails((prev) => {
                            return { ...prev, date: "" };
                          });
                        }
                        setDetails((prev) => {
                          return { ...prev, [chi?.name]: !chi?.active };
                        });
                      }}
                      key={chi?.label}
                      id={chi?.label}
                    />
                    <p className="label grey-white-color-white">{chi.label}</p>
                  </div>
                );
              })}
            </div>
            {/* list box end */}
            {/* date input field start */}
            <div
              className={`date-input-wrap-box-stream ${
                details?.enable_date ? "date-input-wrap-box-stream-show" : ""
              }`}
            >
              <RavenInputField
                dateOptions={{ minDate: new Date() }}
                // enableTime
                placeholder="Select expiry date"
                value={details?.date}
                onChange={(date) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      date: getDateTimeObject(date[0])?.date,
                    };
                  });
                }}
                type="date"
                color="black-light"
              />
            </div>
            {/* date input field end */}
          </div>
          {/* form box end */}
        </div>
      </RavenModal>
    </>
  );
};

export default GenerateStreamKeyModal;
