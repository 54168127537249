import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const getPosDashboardInfo = createAsyncThunk(
  "admin/get-pos-dashboard-info",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/dashboard?range_date=${payload?.range || ""}&start_date=${
          payload?.start_date || ""
        }&end_date=${payload?.end_date || ""}&specific_date=${
          payload?.specific || ""
        }`,
        payload
      );
      // console.log("register---------------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setDashboard(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      // console.log(err);
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const quickTransferPos = createAsyncThunk(
  "transfer/initiate_bank_transfer-pos",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/transfer", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(` ${data?.response?.data?.message}`, {});
      }

      if (data?.data?.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log("data status)");
        // console.log(data);
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAlertReceiverFunc = createAsyncThunk(
  "web/get-alert-receiver",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/get-alert-details?tid=${payload?.tid || ""}`,
        payload
      );
      // console.log("register---------------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setAlertReceiver(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      // console.log(err);
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const addAlertReceiverFunc = createAsyncThunk(
  "web/add-alert-reciever",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/add-alert-contact", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(` ${data?.response?.data?.message}`, {});
      }

      if (data?.data?.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log("data status)");
        // console.log(data);
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPosAdminInfo = createAsyncThunk(
  "admin/get-pos-admin-info",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/pos/get/admin_info", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setAdminInfo(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const downloadPosStatement = createAsyncThunk(
  "admin/pos-download-statement",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/statements?startDate=${payload?.start_date || ""}&stopDate=${
          payload?.stop_date || ""
        }&range_date=${payload?.range_date || ""}&file_type=${
          payload?.file_type || "pdf"
        }&send_to=${payload?.send_to || "download"}&tid=${payload?.tid || ""}`,
        payload
      );
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // await thunkAPI.dispatch(setAdminInfo(data.data.message));
        return data;
      }
    } catch (err) {
      // ;
      // console.log(err);
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getPosTerminalRequestStatus = createAsyncThunk(
  "admin/get-pos-dashboard-info-erg",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/pos/terminal-requests", payload);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        const val = data.data.data;
        // console.log(val);
        await thunkAPI.dispatch(setRequestData(val));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getAllTerminals = createAsyncThunk(
  "admin/get-all-terminals",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/pos/fetch-terminals", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setTerminals(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

// business/v2/get_business_info

export const getTerminalBusinessInfo = createAsyncThunk(
  "admin/pos/get/terminal-info",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/pos/bank-box-profile", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data.data.data);
        await thunkAPI.dispatch(setTerminalBusinessInfo(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getCashiers = createAsyncThunk(
  "admin/pos/get/cashiers",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/pos/get/cashiers", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setCashiers(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getTerminalinformations = createAsyncThunk(
  "admin/terminal-info-pos",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `pos/get/terminal_transactions?current_page=${
          payload?.current_page || 1
        }&search_term=${payload?.search_term || ""}&start_date=${
          payload?.start_date || ""
        }&stop_date=${payload?.stop_date || ""}&type=${
          payload?.type || ""
        }&per_page=${payload?.per_page || 10}&range_date=${
          payload?.range || ""
        }&specific_date=${payload?.specific || ""}`
      );
      // console.log("register------checking", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setTerminalInfo(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const generatePosStatementAll = createAsyncThunk(
  "admin/all-pos-statement",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/statements?date=${payload?.date || ""}&from_date=${
          payload?.from || ""
        }&to_date=${payload?.to || " "}`
      );
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // await thunkAPI.dispatch(setTerminalInfo(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const generatePosStatementSingle = createAsyncThunk(
  "admin/all-pos-statement-single",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/statements?date=${payload?.date || ""}&tid=${
          payload?.tid || ""
        }&from_date=${payload?.from || ""}&to_date=${payload?.to || ""}`
      );
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // await thunkAPI.dispatch(setTerminalInfo(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getTerminalinformationSingle = createAsyncThunk(
  "admin/terminal-info-single",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/fetch-single-terminals?serial_number=${
          payload?.serial || localStorage?.getItem("ter_serial_no") || ""
        }`
      );
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setTerminalInfoSingle(data.data.data[0]));
        // await thunkAPI.dispatch(setTerminalInfo(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getTerminalTransactionSingle = createAsyncThunk(
  "admin/terminal-info-single-transaction",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/get-single-terminal-transactions?page_no=${
          payload?.current_page || 1
        }${payload?.per_page ? `&per_page=${payload?.per_page || ""}` : ``}${
          payload?.start_date ? `&start_date=${payload?.start_date || ""}` : ``
        }${payload?.end_date ? `&end_date=${payload?.end_date || ""}` : ``}${
          payload?.search_term ? `&search=${payload?.search_term || ""}` : ``
        }${payload?.serial ? `&serial=${payload?.serial || ""}` : ``}${
          payload?.tid ? `&tid=${payload?.tid || payload?.id || ""}` : ``
        }`
      );
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        await thunkAPI.dispatch(setTerminalTransactionSingle(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getTerminalTransactionAll = createAsyncThunk(
  "admin/terminal-info-all-transaction",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/get-single-terminal-transactions?page_no=${
          payload?.current_page || 1
        }${payload?.per_page ? `&per_page=${payload?.per_page || ""}` : ``}${
          payload?.start_date ? `&start_date=${payload?.start_date || ""}` : ``
        }${payload?.end_date ? `&end_date=${payload?.end_date || ""}` : ``}${
          payload?.search_term ? `&search=${payload?.search_term || ""}` : ``
        }`
      );
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        await thunkAPI.dispatch(setTerminalTransactionAll(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getTerminals = createAsyncThunk(
  "admin/get-terminal-info",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`pos/get/terminals`);
      console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // await thunkAPI.dispatch(setTerminals(data.data.data.terminals));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getAlertDetails = createAsyncThunk(
  "admin/get-alert-details",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`/pos/get-alert-details`);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // await thunkAPI.dispatch(setTerminals(data.data.data.terminals));
        console.log(data);
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const editTerminalLabelInfo = createAsyncThunk(
  "admin/pos/edit-terminal-label",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/update-terminal-label", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const generateFlierFunc = createAsyncThunk(
  "admin/pos/generateFlier",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/generate-flier", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setFlier(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const shareFlierFunc = createAsyncThunk(
  "admin/pos/shareFlier",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/send-flier", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(setFlier(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const createCashiers = createAsyncThunk(
  "admin/pos/create/cashiers",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/create_cashier", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const deleteAlertReciever = createAsyncThunk(
  "admin/pos/delete/alert-reciever",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/remove-contact", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const deleteCashiers = createAsyncThunk(
  "admin/pos/delete/cashiers",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/remove_cashier", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const disableTermial = createAsyncThunk(
  "admin/pos/disable/terminal",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/deactivate/terminal", payload);
      // console.log("register------", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getPosTransactions = createAsyncThunk(
  "admin/get-pos-transactions",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getPosTransactionByFilter = createAsyncThunk(
  "admin/get-pos-transactions-filter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getPosTransaction = createAsyncThunk(
  "admin/get-pos-transaction",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getPosTransactionsPagination = createAsyncThunk(
  "admin/get-payment-links-pagination",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const disablePos = createAsyncThunk(
  "admin/disable-pos",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.delete("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const requestPos = createAsyncThunk(
  "admin/dashboard-pos-request-pos",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/request-bank-box", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(setPreviousDisbursement(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const requestPosVirtual = createAsyncThunk(
  "admin/dashboard-pos-request-pos-virtual",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/virtual-terminal-request", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(setPreviousDisbursement(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const checkPosVirtualAccountExist = createAsyncThunk(
  "admin/dashboard-pos-request-pos-virtual-check",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/pos/generate-new-account-number`
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        // toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {});
        // await thunkAPI.dispatch(setPreviousDisbursement(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const manageSettlement = createAsyncThunk(
  "admin/pos-manage-settle-ment",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/pos/manage_settlement", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(setPreviousDisbursement(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const fundPos = createAsyncThunk(
  "admin/fund-pos",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const pos = createSlice({
  name: "pos",
  initialState: {
    pending_terminals: [],
    pos_dashboard: {},
    terminalInfo: {},
    terminalInfoSingle: {},
    terminalTransactionSingle: {},
    terminalTransactionAll: {},
    terminalBusinessInfo: {},
    adminInfo: {},
    allPos: [],
    terminals: [],
    cashiers: [],
    status: [],
    pos: {},
    isAuth: false,
    loading: false,
    loading_alert: false,
    loading_trans: false,
    loadingSettlement: false,
    alert_receivers: [],
    flier_to_use: "",
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setPos: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.allPos = action.payload;
    },
    setDashboard: (state, action) => {
      state.isAuth = true;
      state.pos_dashboard = action.payload;
    },
    setFlier: (state, action) => {
      state.isAuth = true;
      state.flier_to_use = action.payload;
    },
    setAlertReceiver: (state, action) => {
      state.isAuth = true;
      state.alert_receivers = action.payload;
    },
    setRequestData: (state, action) => {
      state.isAuth = true;
      state.pending_terminals = action.payload;
    },
    setTerminalBusinessInfo: (state, action) => {
      state.isAuth = true;
      state.terminalBusinessInfo = action.payload;
    },
    setCashiers: (state, action) => {
      state.isAuth = true;
      state.cashiers = action.payload;
    },
    setTerminalInfo: (state, action) => {
      state.isAuth = true;
      state.terminalInfo = action.payload;
    },
    setTerminalInfoSingle: (state, action) => {
      state.isAuth = true;
      state.terminalInfoSingle = action.payload;
    },

    setTerminalTransactionSingle: (state, action) => {
      state.isAuth = true;
      state.terminalTransactionSingle = action.payload;
    },
    setTerminalTransactionAll: (state, action) => {
      state.isAuth = true;
      state.terminalTransactionAll = action.payload;
    },
    setTerminals: (state, action) => {
      state.isAuth = true;
      state.terminals = action.payload;
    },
    setStatus: (state, action) => {
      state.isAuth = true;
      state.status = action.payload;
    },
    setAdminInfo: (state, action) => {
      state.isAuth = true;
      state.adminInfo = action.payload;
    },
  },
  extraReducers: {
    [getAllTerminals.pending]: (state) => {
      state.loading = true;
    },
    [getAllTerminals.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllTerminals.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPosTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getPosTransactions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPosTransactions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAlertReceiverFunc.pending]: (state) => {
      state.loading_alert = true;
    },
    [getAlertReceiverFunc.fulfilled]: (state) => {
      state.loading_alert = false;
    },
    [getAlertReceiverFunc.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_alert = false;
      state.isAuth = false;
      state = null;
    },
    [deleteAlertReciever.pending]: (state) => {
      state.loading_alert = true;
    },
    [deleteAlertReciever.fulfilled]: (state) => {
      state.loading_alert = false;
    },
    [deleteAlertReciever.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_alert = false;
      state.isAuth = false;
      state = null;
    },
    [addAlertReceiverFunc.pending]: (state) => {
      state.loading_alert = true;
    },
    [addAlertReceiverFunc.fulfilled]: (state) => {
      state.loading_alert = false;
    },
    [addAlertReceiverFunc.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_alert = false;
      state.isAuth = false;
      state = null;
    },
    [getTerminalinformations.pending]: (state) => {
      state.loading = true;
    },
    [getTerminalinformations.fulfilled]: (state) => {
      state.loading = false;
    },
    [getTerminalinformations.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [requestPos.pending]: (state) => {
      state.loading = true;
    },
    [requestPos.fulfilled]: (state) => {
      state.loading = false;
    },
    [requestPos.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [requestPosVirtual.pending]: (state) => {
      state.loading = true;
    },
    [requestPosVirtual.fulfilled]: (state) => {
      state.loading = false;
    },
    [requestPosVirtual.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [checkPosVirtualAccountExist.pending]: (state) => {
      state.loading_alert = true;
    },
    [checkPosVirtualAccountExist.fulfilled]: (state) => {
      state.loading_alert = false;
    },
    [checkPosVirtualAccountExist.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_alert = false;
      state.isAuth = false;
      state = null;
    },
    [quickTransferPos.pending]: (state) => {
      state.loading = true;
    },
    [quickTransferPos.fulfilled]: (state) => {
      state.loading = false;
    },
    [quickTransferPos.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getTerminalinformationSingle.pending]: (state) => {
      state.loading = true;
    },
    [getTerminalinformationSingle.fulfilled]: (state) => {
      state.loading = false;
    },
    [getTerminalinformationSingle.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPosDashboardInfo.pending]: (state) => {
      state.loading = true;
    },
    [getPosDashboardInfo.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPosDashboardInfo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [manageSettlement.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [manageSettlement.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [manageSettlement.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
      state.isAuth = false;
      state = null;
    },
    [createCashiers.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [createCashiers.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [createCashiers.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
      state.isAuth = false;
      state = null;
    },
    [deleteCashiers.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [deleteCashiers.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [deleteCashiers.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
      state.isAuth = false;
      state = null;
    },
    [disableTermial.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [disableTermial.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [disableTermial.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
    },
    [downloadPosStatement.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [downloadPosStatement.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [downloadPosStatement.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
    },
    [editTerminalLabelInfo.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [editTerminalLabelInfo.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [editTerminalLabelInfo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
    },
    [generateFlierFunc.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [generateFlierFunc.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [generateFlierFunc.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
    },
    [shareFlierFunc.pending]: (state) => {
      state.loadingSettlement = true;
    },
    [shareFlierFunc.fulfilled]: (state) => {
      state.loadingSettlement = false;
    },
    [shareFlierFunc.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSettlement = false;
    },
    [getTerminalTransactionSingle.pending]: (state) => {
      state.loading_trans = true;
    },
    [getTerminalTransactionSingle.fulfilled]: (state) => {
      state.loading_trans = false;
    },
    [getTerminalTransactionSingle.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_trans = false;
    },
    [getTerminalTransactionAll.pending]: (state) => {
      state.loading_trans = true;
    },
    [getTerminalTransactionAll.fulfilled]: (state) => {
      state.loading_trans = false;
    },
    [getTerminalTransactionAll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_trans = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setPos,
  setDashboard,
  setCashiers,
  setTerminalInfo,
  setTerminalTransactionSingle,
  setTerminalTransactionAll,
  setTerminals,
  setStatus,
  setRequestData,
  setAdminInfo,
  setTerminalInfoSingle,
  setAlertReceiver,
  setTerminalBusinessInfo,
  setFlier,
} = pos.actions;

export default pos.reducer;
