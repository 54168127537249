import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { disableTermial } from "../../../../redux/pos";
import { trimLongString } from "../../../../utils/helper/Helper";

const DeleteTerminalModal = ({ visible, onClose, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loadingSettlement, terminalInfoSingle } = useSelector(
    (state) => state.pos
  );

  const handleSubmit = async () => {
    const obj = {
      tid: terminalInfoSingle?.tid || localStorage?.getItem("ter_serial_no"),
    };

    const data = await dispatch(disableTermial(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };

  return (
    <DeleteModal
      loading={loadingSettlement}
      title={`Disable  Terminal POS ( ${trimLongString(
        terminalInfoSingle?.tid_label,
        17
      )} )`}
      text={`This terminal ( ${terminalInfoSingle?.tid_label} ) won’t be able to receive or process transaction any more, unless you reach our customer care agent to unblock your terminal. do you wish to proceed`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
    />
  );
};

export default DeleteTerminalModal;
