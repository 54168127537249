import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useRef, useState } from "react";
import "../../../../styles/dashboard/pos/AddAlertRecievers.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { editTerminalLabelInfo } from "../../../../redux/pos";
import BadgeComponent from "../../../../components/fragments/badge/BadgeComponent";
import { removeSpace } from "../../../../utils/helper/Helper";
import { useDarkMode } from "../../../../utils/themes/useDarkMode";
import { FaCheck } from "react-icons/fa";

const ManageStreamKeyModal = ({ visible, onClose, onFinish }) => {
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [copyInfo, setCopyInfo] = useState("");
  const handleCopy = async (param) => {
    setCopyInfo(param);
    await navigator.clipboard.writeText(param);
    setTimeout(() => {
      setCopyInfo("");
    }, 1000);
  };
  const { user } = useSelector((state) => state.user);
  const [mode, setMode] = useState("view");
  const { loadingSettlement, terminalInfoSingle } = useSelector(
    (state) => state.pos
  );
  const handleClose = () => {
    onClose && onClose();
    // setCompletePin(false);
  };
  const dispatch = useDispatch();

  const [completePin, setCompletePin] = useState(false);
  const [details, setDetails] = useState({
    two_factor_token: "",
    label: "",
    stream_key: "902728",
    enable_history: false,
    enable_date: false,
    date: "",
  });
  const pinRefVal = useRef();
  // const location =

  useEffect(() => {
    setCompletePin(false);
    if (Object?.keys(terminalInfoSingle)?.length > 0 && visible) {
      setDetails((prev) => {
        return { ...prev, label: terminalInfoSingle?.tid_label };
      });
    }
    // pinRefVal.current.forEach((input) => (input.value = ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    const newObj = { ...details, [name]: value };
    setDetails(newObj);
  };

  const handleSubmit = async () => {
    if (mode === "edit") {
      const obj = {
        tid: terminalInfoSingle?.tid,
        new_label_name: details?.label,
      };
      // console.log(obj);

      // const data = await dispatch(editTerminalLabelInfo(obj));
      // if (data?.payload?.data?.status === "success") {
      //   onFinish();
      //   handleClose();
      // }
    } else {
      onClose && onClose();
      // setDetails((prev) => {
      //   return { ...prev, label: "" };
      // });
    }
  };

  function generateAccountNumber() {
    const key = Array.from({ length: 6 }, () =>
      Math.floor(Math.random() * 6)
    ).join("");
    setDetails((prev) => {
      return {
        ...prev,
        stream_key: key,
      };
    });
  }

  const settingList = [
    {
      label: "Enable Transaction History",
      active: details?.enable_history,
      name: "enable_history",
    },
    {
      label: details?.date
        ? `Expires on ${details?.date ? formatDate(details?.date) : ""}`
        : `Set Expiry Date`,
      active: details?.enable_date,
      name: "enable_date",
    },
  ];

  const handleDisable = () => {
    // let val;
    if (details?.enable_date) {
      const data =
        !details?.stream_key ||
        removeSpace(String(details?.stream_key))?.length < 6 ||
        !details?.date;
      return data;
    } else {
      const data =
        !details?.stream_key ||
        removeSpace(String(details?.stream_key))?.length < 6;
      return data;
    }
    // return val;
  };

  function getDateTimeObject(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return {
      date: `${year}-${month}-${day}`, // YYYY-MM-DD
      time: `${hours}:${minutes}:${seconds}`, // HH:mm:ss
    };
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format. Expected YYYY-MM-DD.");
    }

    const options = {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const [weekday, month, day, year] = formattedDate.split(" ");

    return `${weekday?.replace(",", "")} ${day?.replace(
      ",",
      ""
    )}, ${month}, ${year}`;
  }

  const viewList = [
    { label: "Stream Key", value: details?.stream_key, copy: true, edit: true },
    { label: "Account No", value: "4592100827", copy: true, edit: false },
  ];

  const editIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      fill="none"
      viewBox="0 0 14 13"
      className="img"
    >
      <path
        stroke={theme === "light" ? "#000" : "#ffffff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.199"
        d="M6.81 10.764h4.797m-9.594 0h.892c.261 0 .391 0 .514-.03q.165-.038.308-.127c.108-.066.2-.158.384-.342l6.697-6.697a1.13 1.13 0 1 0-1.6-1.599L2.513 8.666c-.184.184-.276.276-.342.384q-.089.144-.128.308c-.03.123-.03.253-.03.514z"
      ></path>
    </svg>
  );

  const copyIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
      className="img"
    >
      <g clipPath="url(#clip0_21748_10638)">
        <path
          stroke={theme === "light" ? "#020202" : "#ffffff"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.199"
          d="M8.735 4.368V2.875c0-.597 0-.895-.116-1.123a1.07 1.07 0 0 0-.466-.466c-.228-.116-.527-.116-1.124-.116H2.98c-.598 0-.896 0-1.124.116-.201.102-.364.265-.466.466-.117.228-.117.526-.117 1.123v4.052c0 .597 0 .895.117 1.123.102.2.265.364.466.466.228.116.526.116 1.123.116h1.493m1.705 3.199h4.051c.598 0 .896 0 1.124-.117.2-.102.364-.265.466-.466.116-.228.116-.526.116-1.123V6.074c0-.597 0-.896-.116-1.124a1.07 1.07 0 0 0-.466-.466c-.228-.116-.526-.116-1.124-.116h-4.05c-.598 0-.896 0-1.124.116-.2.102-.364.265-.466.466-.116.228-.116.527-.116 1.124v4.05c0 .598 0 .896.116 1.124.102.201.265.364.466.466.228.117.526.117 1.123.117"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_21748_10638">
          <path fill="#fff" d="M.207.104H13v12.793H.207z"></path>
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <RavenModal
        onClose={handleClose}
        btnLabel={mode === "view" ? "close" : "Update Stream Key"}
        btnColor="black-light"
        visble={visible}
        disabled={mode === "edit" ? handleDisable() : false}
        onBtnClick={handleSubmit}
        loading={loadingSettlement}
      >
        <div
          // onClick={() => {
          //   console.log(terminalInfoSingle);
          // }}
          className="edit-single-terminal-label-wrapper-index-box"
          style={{ paddingBottom: "3rem" }}
        >
          <div className="title-text-box border-theme-bottom">
            <p className="title">Manage Stream Key</p>
            <p className="text grey-white-color">
              Use the stream key to manage your transactions and your inflows.
            </p>
          </div>
          {/* form box start */}
          <div className="form-box">
            <div
              className={`edit-info-mode-wrap-box ${
                mode === "edit" ? "edit-info-mode-wrap-box-show" : ""
              }`}
            >
              {" "}
              {/* input field start */}
              <div className="stream-number-input-box">
                <RavenInputField
                  type="account-number"
                  color="black-light"
                  className="input"
                  placeholder="000000"
                  value={details?.stream_key}
                  name={`stream_key`}
                  onChange={handleChange}
                  maxLength={6}
                />
              </div>
              {/* input field end */}
              {/* down label box start */}
              <div className="down-label-generate-box">
                <p className="label-down grey-white-color">
                  Enter a 6 digit code or{" "}
                </p>
                <BadgeComponent
                  onClick={generateAccountNumber}
                  className={`pending badge`}
                  text={`Auto Generate Key`}
                />
              </div>
              {/* down label box end */}
            </div>

            {/* edit or view moe start */}
            <div
              className={`view-info-mode-wrap-box grey-bg ${
                mode === "view" ? "view-info-mode-wrap-box-show" : ""
              }`}
            >
              {viewList?.map((chi, idx) => {
                return (
                  <div
                    key={idx}
                    className="label-value-copy-icon-box border-theme-bottom"
                  >
                    {/* label value box start*/}
                    <div className="label-value-box">
                      <p className="label grey-white-color">{chi?.label}</p>
                      <p className="value">{chi?.value}</p>
                    </div>
                    {/* label value box end*/}
                    {/* icon wraps box start */}
                    <div className="icon-wraps-box">
                      {/* icon box start */}
                      {chi?.edit && (
                        <div
                          onClick={() => {
                            setMode("edit");
                          }}
                          className="icon-box card-bg"
                        >
                          <figur className="img-box">{editIcon}</figur>
                        </div>
                      )}
                      {/* icon box end */}
                      {/* icon box start */}
                      <div
                        className={`icon-box card-bg ${
                          copyInfo === chi?.value ? "black-white-bg" : ""
                        }`}
                        onClick={() => {
                          handleCopy(chi?.value);
                        }}
                      >
                        {copyInfo === chi?.value ? (
                          <>
                            <div className="img-box">
                              {" "}
                              <FaCheck className={"white-black-color"} />
                            </div>
                          </>
                        ) : (
                          <figure className="img-box">{copyIcon}</figure>
                        )}
                      </div>
                      {/* icon box end */}
                    </div>
                    {/* icon wraps box end */}
                  </div>
                );
              })}
            </div>
            {/* edit or view moe end */}

            {/* list box start */}
            <div className="list-box-to-set">
              {settingList?.map((chi, idx) => {
                return (
                  <div key={idx} className="label-check-box">
                    <RavenCheckBox
                      color="black-light"
                      checked={chi?.active}
                      disabled={mode === "view" ? true : false}
                      onChange={() => {
                        if (
                          chi?.name === "enable_date" &&
                          !details?.enable_date
                        ) {
                          setDetails((prev) => {
                            return { ...prev, date: "" };
                          });
                        }
                        setDetails((prev) => {
                          return { ...prev, [chi?.name]: !chi?.active };
                        });
                      }}
                      key={`${chi?.label}-jhss`}
                      id={`${chi?.label}-jhss`}
                    />
                    <p className="label grey-white-color-white">{chi.label}</p>
                  </div>
                );
              })}
            </div>
            {/* list box end */}
            {/* date input field start */}
            <div
              className={`date-input-wrap-box-stream ${
                details?.enable_date ? "date-input-wrap-box-stream-show" : ""
              }`}
            >
              <RavenInputField
                dateOptions={{ minDate: new Date() }}
                // enableTime
                placeholder="Select expiry date"
                value={details?.date}
                onChange={(date) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      date: getDateTimeObject(date[0])?.date,
                    };
                  });
                }}
                type="date"
                color="black-light"
              />
            </div>
            {/* date input field end */}
          </div>
          {/* form box end */}
        </div>
      </RavenModal>
    </>
  );
};

export default ManageStreamKeyModal;
