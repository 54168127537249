import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useRef, useState } from "react";
import "../../../../styles/dashboard/pos/AddAlertRecievers.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { editTerminalLabelInfo } from "../../../../redux/pos";

const EditTerminalLabelModal = ({ visible, onClose, onFinish }) => {
  const { user } = useSelector((state) => state.user);
  const { loadingSettlement, terminalInfoSingle } = useSelector(
    (state) => state.pos
  );
  const handleClose = () => {
    onClose && onClose();
    setDetails((prev) => {
      return { ...prev, label: "" };
    });

    setCompletePin(false);
  };
  const dispatch = useDispatch();

  const [completePin, setCompletePin] = useState(false);
  const [details, setDetails] = useState({
    two_factor_token: "",
    label: "",
  });
  const pinRefVal = useRef();
  // const location =

  useEffect(() => {
    setCompletePin(false);
    if (Object?.keys(terminalInfoSingle)?.length > 0 && visible) {
      setDetails((prev) => {
        return { ...prev, label: terminalInfoSingle?.tid_label };
      });
    }
    // pinRefVal.current.forEach((input) => (input.value = ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    const newObj = { ...details, [name]: value };
    setDetails(newObj);
  };

  const handleSubmit = async () => {
    const obj = {
      tid: terminalInfoSingle?.tid,
      new_label_name: details?.label,
    };
    // console.log(obj);

    const data = await dispatch(editTerminalLabelInfo(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
      handleClose();
    }
  };

  return (
    <>
      <RavenModal
        onClose={handleClose}
        btnLabel="Update"
        btnColor="black-light"
        visble={visible}
        disabled={details?.label === terminalInfoSingle?.tid_label}
        onBtnClick={handleSubmit}
        loading={loadingSettlement}
      >
        <div
          // onClick={() => {
          //   console.log(terminalInfoSingle);
          // }}
          className="edit-single-terminal-label-wrapper-index-box"
        >
          <div className="title-text-box border-theme-bottom">
            <p className="title">Update Terminal Label</p>
            <p className="text grey-white-color">
              This would update the name of your terminal across all platform
            </p>
          </div>
          {/* form box start */}
          <div className="form-box">
            <RavenInputField
              color="black-light"
              type="text"
              label="Terminal label * "
              placeholder="E.g AKOT Counter "
              value={details?.label}
              onChange={handleChange}
              name="label"
            />
            {/* two factor code start */}
            {String(user?.two_factor) === "1" && (
              <></>
              // <div className="two-factor-box-wrap">
              //   <div className="text-line">
              //     <span></span>
              //     <p className="text">Enter 6 digit 2fa Code</p>
              //     <span></span>
              //   </div>
              //   <form action="" autoComplete="off">
              //     <RavenInputField
              //       pinRef={pinRefVal}
              //       type={`pin`}
              //       color={`black-light`}
              //       onComplete={(num) => {
              //         setCompletePin(true);
              //         //   onComplete && onComplete(num);
              //       }}
              //       label="2FA PIN * "
              //       id="tokn-two-fa"
              //       value={details?.two_factor_token}
              //       onChange={(num) => {
              //         setDetails((prev) => {
              //           return { ...prev, two_factor_token: num };
              //         });
              //         setCompletePin(false);
              //       }}
              //     />
              //   </form>
              // </div>
            )}
            {/* two factor code end */}
          </div>
          {/* form box end */}
        </div>
      </RavenModal>
    </>
  );
};

export default EditTerminalLabelModal;
