/* eslint-disable no-unused-vars */
import {
  // RavenButton,
  RavenInputField,
  RavenNumberFormat,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
  // RavenPagination,
  // RavenTable,
  // RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  createCashiers,
  getAllTerminals,
  getCashiers,
  // getPosAdminInfo,
  // getPosAdminInfoTest,
  // getPosDashboardInfo,
  getPosTerminalRequestStatus,
  getTerminalBusinessInfo,
  getTerminalTransactionAll,
} from "../../../redux/pos";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import {
  calculateDateRange,
  capitalizeFirstLetter,
  filterEmptyColumns,
  formatDate,
  formatMetaData,
  formatNUmPan,
  formatNumWithCommaNairaSymbol,
  formatStringPermissionToLowerCase,
  formatTypeFunction,
  // getPosTerminalRequest,
  getTotalPage,
  // getStartAndStopDates,
  reactSelectStyle,
  trimLongString,
  // trimLongString,
} from "../../../utils/helper/Helper";
import AssignCashierModal from "./modal/AssignCashierModal";
import DeleteCashierModal from "./modal/DeleteCashierModal";
import DeleteTerminalModal from "./modal/DeleteTerminalModal";
import ActionContext from "../../../context/ActionContext";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
// import MobileTableCard from "../../../components/reusables/MobileTableCard";
import noContentImg from "../../../assets/no-pos-content-img.png";
import NocontentPage from "../../../components/reusables/NocontentPage";
import BlurWrap from "../../../components/fragments/BlurWrap";
// import TransactionDetailModal from "./modal/TransactiondetailModal";
// import walletIcon from "../../../assets/wallet-bal-icon.svg";
// import cashInIcon from "../../../assets/cash-in-icon.svg";
// import cashOutIcon from "../../../assets/cash-out-icon.svg";
import ViewCashierModal from "./modal/ViewCashierModal";
// import TopUpCardModal from "../card/modals/TopUPCardModal";
import ViewTopUPModal from "./modal/ViewTopUPModal";
import GenerateStatementModal from "./modal/GenerateAllPosStatement";
import SpecificDateSelectModal from "./modal/SpecificDateSelectModal";
// import moment from "moment";
import CustomDateSelectModal from "./modal/CustomDateSelectModal";
import SettlementSettingModal from "./modal/SettlementSettingModal";
import { fetchBanks } from "../../../redux/transaction";
import TrackDeliveryModal from "./modal/TrackDeliveryModal";
// import AlertRecieversModal from "./modal/AlertRecieversModal";
// import DeleteAlertRecieverModal from "./modal/DeleteAlertReceiverModal";
// import AddAlertReceiver from "./modal/AddAlertReciever";
import BadgeComponent from "../../../components/fragments/badge/BadgeComponent";
import useDebounce from "../../../utils/helper/UseDebounce";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import DownloadReceiptModal from "./modal/DetailTransactionModal";
import { POS_ICON } from "./PosIcon";
import { useDarkMode } from "../../../utils/themes/useDarkMode";
// import GenerateStatementModal from "../settings/sections/modal/GenerateStatementModal";

const DashboardPosIndexNew = () => {
  const [singleChild, setSingleChild] = useState({});
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const actionCtx = useContext(ActionContext);  
  // const [loadingPage, setLoadingPage] = useState(true);
  const [sorceFilter] = useState("");
  const [singleObj, setSingleObj] = useState({});
  const [showModal, setShowModal] = useState({
    assign_cashier: false,
    delete_cashier: false,
    delete_terminal: false,
    view: false,
    view_cashier: false,
    top_up: false,
    statement: false,
    selectSpecificDate: false,
    customDate: false,
    settle: false,
    // view: false,
  });
  const [details, setDetails] = useState({
    cashier_phone: "",
    cashier_name: "",
    cashier_pin: "",
    pin: "1111",
  });
  const { user_resource_and_permissions, business } = useSelector(
    (state) => state.user
  );
  const {
    // pos_dashboard,
    loading,
    cashiers,
    loadingSettlement,
    // status,
    pending_terminals,
    terminals,
    terminalBusinessInfo,
    loading_trans,
    terminalTransactionAll,
  } = useSelector((state) => state.pos);
  // const [copyItem, setCopyItem] = useState("");
  const [urlBack, setUrlBack] = useState("");
  //   const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const headerList = ["NARRATION", "AMOUNT", "SOURCE", " DATE", "STATUS"];
  const dispatch = useDispatch();
  // const [requestStatus, setRequestStatus] = useState("");
  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const { bankList } = useSelector((state) => state.transaction);
  useEffect(() => {
    if (bankList?.length < 1) {
      dispatch(fetchBanks());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const purpleNavIcon = (
    <svg
      className="img"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.07422" cy="8" r="8" fill="#F0EEFF" />
      <g clipPath="url(#clip0_15985_77757)">
        <path
          d="M8.07438 12.1666C10.3756 12.1666 12.241 10.3012 12.241 7.99998C12.241 5.69879 10.3756 3.83331 8.07438 3.83331C5.7732 3.83331 3.90771 5.69879 3.90771 7.99998C3.90771 10.3012 5.7732 12.1666 8.07438 12.1666Z"
          fill="#755AE2"
        />
        <path
          d="M8.07438 9.66665L9.74105 7.99998L8.07438 6.33331"
          fill="#755AE2"
        />
        <path
          d="M8.07438 9.66665L9.74105 7.99998M9.74105 7.99998L8.07438 6.33331M9.74105 7.99998H6.40771M12.241 7.99998C12.241 10.3012 10.3756 12.1666 8.07438 12.1666C5.7732 12.1666 3.90771 10.3012 3.90771 7.99998C3.90771 5.69879 5.7732 3.83331 8.07438 3.83331C10.3756 3.83331 12.241 5.69879 12.241 7.99998Z"
          stroke="#F0EEFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15985_77757">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(3.07422 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const handleSubmitDetails = async (pin, token) => {
    const obj = {
      ...details,
      pin: pin,
      two_factor_token: token,
    };
    // console.log(obj);
    // return
    const data = await dispatch(createCashiers(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
      setDetails({
        cashier_phone: "",
        cashier_name: "",
        cashier_pin: "",
      });
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        assign_cashier: false,
        delete_cashier: false,
        delete_terminal: false,
        view_cashier: false,
        view: false,
        settle: false,
      };
    });
    dispatch(getCashiers());
  };

  const noContentList = [
    "Accurate transaction processing, improving customer satisfaction and reducing errors.",
    "flexibility in payment options, allowing customers to pay with their preferred method.",
    "Enhanced security by reducing the risk of fraud and theft",
  ];

  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const year = yesterday.getFullYear();
  const month = yesterday.getMonth() + 1; // Months are zero-indexed, so we add 1
  const day = yesterday.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;

  const filterOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: formattedDate },
    { label: "7 days", value: "1_weeek" },
    { label: "14 days", value: "2_weeks" },
    { label: "3 Weeks", value: "3_weeks" },
    { label: "1 Month", value: "1_month" },
    { label: "2 Months", value: "2_months" },
    { label: "3 Months", value: "3_months" },
    { label: "Specific Date", value: "specific_date" },
    { label: "Custom", value: "custom" },
  ];
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: { label: "Today", value: "today" },
    two: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [filterVal, setFilterVal] = useState({
    label: "Today",
    value: "today",
  });

  //  filter start ----------------------------------------------

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      let val;
      if (filterVal?.name === "Specific Date") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: search,
          specific: filterVal?.value,
        };
        val = payload;
      }
      if (filterVal?.name === "Custom Date") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          start_date: filterVal?.value?.split("_")[0],
          stop_date: filterVal?.value?.split("_")[1],
          search_term: search,
        };
        val = payload;
      }
      if (
        filterVal?.label === "1 Week" ||
        filterVal?.label === "2 Weeks" ||
        filterVal?.label === "3  Weeks" ||
        filterVal?.label === "1 Month" ||
        filterVal?.label === "2 Months" ||
        filterVal?.label === "3 Months"
      ) {
        const payload = {
          // id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          range: filterVal?.value,
          search_term: search,
        };
        val = payload;
      }
      if (filterVal?.label === "Today") {
        const obj = {
          search_term: search,
        };
        val = obj;
      }
      // console.log(val);
      // return;
      getTerminalFunc(val);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      let val;
      if (filterVal?.name === "Specific Date") {
        const payload = {
          // id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          specific: filterVal?.value,
          search_term: "",
          // tid: terminalInfoSingle?.tid,
          // serial: localStorage?.getItem("ter_serial_no"),
        };
        val = payload;
      }
      if (filterVal?.name === "Custom Date") {
        const payload = {
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          start_date: filterVal?.value?.split("_")[0],
          stop_date: filterVal?.value?.split("_")[1],
          search_term: "",
          per_page: actionCtx?.perPageVal?.value,
          serial: localStorage?.getItem("ter_serial_no"),
        };
        val = payload;
      }
      if (
        filterVal?.label === "1 Week" ||
        filterVal?.label === "2 Weeks" ||
        filterVal?.label === "3 Weeks" ||
        filterVal?.label === "1 Month" ||
        filterVal?.label === "2 Months" ||
        filterVal?.label === "3 Months"
      ) {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          transaction_type: selectOptionVal?.two?.value,
          range: filterVal?.value,
          search_term: "",
        };
        val = payload;
        // console.log(val);

        // return
      }
      if (filterVal?.label === "Today") {
        const obj = {
          search_term: "",
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
        };
        val = obj;
      }
      // console.log(val);
      // return
      getTerminalFunc(val);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  // search end -----

  useEffect(() => {
    if (
      filterVal?.label === "1 Week" ||
      filterVal?.label === "2 Weeks" ||
      filterVal?.label === "3  Weeks" ||
      filterVal?.label === "1 Month" ||
      filterVal?.label === "2 Months" ||
      filterVal?.label === "3 Months"
    ) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        // range: filterVal?.value,
        search_term: search,
        start_date: calculateDateRange(filterVal?.value)?.startDate,
        end_date: calculateDateRange(filterVal?.value)?.endDate,
        // id: terminal_id,
        // tid: terminalInfoSingle?.tid,
        // serial: localStorage?.getItem("ter_serial_no"),
      };
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  useEffect(() => {
    if (filterVal?.label === "Today") {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        search_term: search,
        // id: terminalInfoSingle?.tid || terminal_id,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        // tid: terminalInfoSingle?.tid,
        // serial: localStorage?.getItem("ter_serial_no"),
      };
      //   console.log(obj);
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal, currentPage]);

  useEffect(() => {
    if (filterVal?.name === "Specific Date") {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        // specific: filterVal?.value,
        start_date: filterVal?.value,
        end_date: filterVal?.value,
        search_term: search,
      };
      // console.log(obj);
      // return
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  useEffect(() => {
    if (filterVal?.name === "Custom Date") {
      const obj = {
        start_date: filterVal?.value?.split("_")[0],
        end_date: filterVal?.value?.split("_")[1],
        type: selectOptionVal?.two?.value,
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
      };
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  //  filter end here -------------------------------------------

  const formatFilterDate = (obj) => {
    const dateOne = formatDate(obj?.split("_")[0])?.split("—")[0];
    const dateTwo = formatDate(obj?.split("_")[1])?.split("—")[0];
    const val = `${dateOne} to ${dateTwo}`;
    // console.log(val);
    return val;
  };

  // new terminal implementation start here -------------------

  const pendingIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
      className="img"
    >
      <path
        fill="url(#paint0_linear_20925_14849)"
        fillRule="evenodd"
        d="M7.37 13.395a5.895 5.895 0 1 0 0-11.79 5.895 5.895 0 0 0 0 11.79m.613-8.658a.614.614 0 0 0-1.228 0V7.5c0 .163.065.32.18.434l1.842 1.843a.614.614 0 1 0 .868-.869L7.983 7.246z"
        clipRule="evenodd"
        opacity="0.9"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_20925_14849"
          x1="7.369"
          x2="7.369"
          y1="1.605"
          y2="13.395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA554"></stop>
          <stop offset="1" stopColor="#E66F04"></stop>
        </linearGradient>
      </defs>
    </svg>
  );

  useEffect(() => {
    dispatch(getAllTerminals());
    if (Object?.keys(terminalBusinessInfo)?.length < 1) {
      dispatch(getTerminalBusinessInfo());
    }

    if (pending_terminals?.length < 1) {
      dispatch(getPosTerminalRequestStatus());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabSelect = [`Terminals`, "All Transactions"];
  const [selectedView, setSelectedView] = useState(tabSelect[0]);

  const getTerminalFunc = async (obj) => {
    !loading_trans && dispatch(getTerminalTransactionAll(obj));
  };

  const formatTypeWrap = (param, chi) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          chi?.direction === "debit" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "failed" && "status-type-wrap-disabled"} ${
          param === "failed" && "dull-bright-filter"
        } ${chi?.direction === null && "status-type-wrap-disabled"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param === "failed"
            ? "Failed"
            : chi?.direction === null
            ? "Failed"
            : chi?.direction}
        </span>
      </div>
    );
  };

  const rowTypeText = (type, text, textTwo, chi) => {
    return (
      <div className="type-text-box">
        {loading ? (
          <>
            {" "}
            <Skeleton
              width={30}
              height={30}
              circle
              style={{ marginRight: "1rem" }}
            />{" "}
            <Skeleton width={200} height={17} />
          </>
        ) : (
          <>
            {" "}
            <div className="img-wrap">
              <figure
                className={`img-box-cre-deb ${
                  type === "debit" && "img-box-debit"
                } img-box-debit-credit ${chi?.status} ${
                  chi?.status === "failed" && "img-box-failed"
                } ${chi?.direction === null && "img-box-failed"}`}
              >
                {type === "credit" ? (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                      stroke="#1ACE37"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                      stroke="#FF0F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </figure>
            </div>
            <div className=" text-box">
              <p className="text">{text || "---"}</p>
              <p className="text-small grey-white-color">
                {textTwo === "undefined" ? "---" : textTwo || "---"}
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const transactionActions = (param) => {
    return (
      <div className="transaction-action-wrap">
        {loading_trans ? (
          <>
            {/* <Skeleton width={35} height={35} circle /> */}
            <Skeleton width={35} height={35} circle />
          </>
        ) : (
          <>
            <figure
              onClick={() => {
                setShowModal((prev) => {
                  return { ...prev, view: true };
                });
                setSingleObj(param);
              }}
              key={param?.id}
              className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`View transaction details`}
                position={`left`}
              />
              <svg
                className="img dull-bright-filter"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.81509 9.53488C1.71295 9.37315 1.66188 9.29229 1.63329 9.16756C1.61182 9.07387 1.61182 8.92613 1.63329 8.83244C1.66188 8.70771 1.71295 8.62685 1.81509 8.46512C2.65915 7.12863 5.17155 3.75 9.0003 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62685 16.3387 8.70771 16.3673 8.83244C16.3888 8.92613 16.3888 9.07387 16.3673 9.16756C16.3387 9.29229 16.2877 9.37315 16.1855 9.53488C15.3415 10.8714 12.8291 14.25 9.0003 14.25C5.17155 14.25 2.65915 10.8714 1.81509 9.53488Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.0003 11.25C10.2429 11.25 11.2503 10.2426 11.2503 9C11.2503 7.75736 10.2429 6.75 9.0003 6.75C7.75766 6.75 6.7503 7.75736 6.7503 9C6.7503 10.2426 7.75766 11.25 9.0003 11.25Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </>
        )}
      </div>
    );
  };

  const virtualIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      className="img"
    >
      <path
        fill="#EA872D"
        d="M9 1.5H3c-1.105 0-2 .89-2 1.985v5.03C1 9.61 1.895 10.5 3 10.5h6c1.105 0 2-.89 2-1.985v-5.03A1.993 1.993 0 0 0 9 1.5M4.25 3.585c.635 0 1.155.52 1.155 1.155s-.52 1.155-1.155 1.155-1.155-.52-1.155-1.155.52-1.155 1.155-1.155M6.185 8.33A.25.25 0 0 1 6 8.41H2.5c-.07 0-.14-.03-.185-.08a.26.26 0 0 1-.065-.19 1.774 1.774 0 0 1 1.595-1.585 4.3 4.3 0 0 1 .805 0A1.77 1.77 0 0 1 6.245 8.14c.01.07-.015.14-.06.19m3.315.045h-1A.38.38 0 0 1 8.125 8c0-.205.17-.375.375-.375h1c.205 0 .375.17.375.375s-.17.375-.375.375m0-2h-2A.38.38 0 0 1 7.125 6c0-.205.17-.375.375-.375h2c.205 0 .375.17.375.375s-.17.375-.375.375m0-2H7A.38.38 0 0 1 6.625 4c0-.205.17-.375.375-.375h2.5c.205 0 .375.17.375.375s-.17.375-.375.375"
      ></path>
    </svg>
  );

  const virtualTerminalIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      className="img"
    >
      <path
        fill={theme === "light" ? "#333" : "#fff"}
        d="M13.333 2.167H6.667A4.17 4.17 0 0 0 2.5 6.333v8.334c0 2.3 1.867 4.166 4.167 4.166h6.666c2.3 0 4.167-1.866 4.167-4.166V6.333c0-2.3-1.867-4.166-4.167-4.166m-5.95 13.858a.82.82 0 0 1-.583.242.83.83 0 0 1-.592-.242.83.83 0 0 1-.25-.592c0-.216.092-.433.25-.591a.8.8 0 0 1 .267-.175.86.86 0 0 1 .642 0c.05.016.1.041.141.075a.848.848 0 0 1 .375.692.83.83 0 0 1-.25.591M5.958 12.1a.8.8 0 0 1 .067-.317.832.832 0 0 1 1.092-.45c.05.017.1.042.141.075.05.025.092.067.125.1a.9.9 0 0 1 .184.275c.041.1.058.209.058.317a.825.825 0 0 1-1.15.767.833.833 0 0 1-.517-.767m4.759 3.925a.8.8 0 0 1-.267.175.82.82 0 0 1-.908-.175.83.83 0 0 1-.25-.592c0-.058.008-.108.016-.166l.05-.15.075-.15c.034-.042.067-.084.109-.125a.848.848 0 0 1 1.175 0 .84.84 0 0 1 .241.591.83.83 0 0 1-.241.592m0-3.333a.83.83 0 0 1-.592.241.82.82 0 0 1-.583-.241.83.83 0 0 1-.25-.592c0-.217.091-.433.25-.592a.86.86 0 0 1 1.175 0 .9.9 0 0 1 .183.275.8.8 0 0 1 .058.317.83.83 0 0 1-.241.592M7.5 9.217A1.57 1.57 0 0 1 5.933 7.65v-.833c0-.859.7-1.567 1.567-1.567h5c.858 0 1.567.7 1.567 1.567v.833c0 .858-.7 1.567-1.567 1.567zm6.55 6.808a.825.825 0 0 1-.908.175.8.8 0 0 1-.267-.175.83.83 0 0 1-.242-.592.84.84 0 0 1 .242-.591c.225-.234.6-.3.908-.175.1.041.192.1.267.175a.84.84 0 0 1 .242.591.83.83 0 0 1-.242.592m.183-3.608a.84.84 0 0 1-.775.516.82.82 0 0 1-.583-.241.83.83 0 0 1-.25-.592c0-.217.092-.433.25-.592a.86.86 0 0 1 1.175 0c.158.159.25.375.25.592a.8.8 0 0 1-.067.317"
      ></path>
    </svg>
  );

  return (
    <>
      <DashboardLayout>
        {pending_terminals?.length || terminals?.length > 0 ? (
          <>
            {/* title box start */}
            <TitletextBox
              title={`BankBox`}
              text={`Manage all your BankBox in one place`}
              back={urlBack}
              onBack={() => {
                navigate(`/${urlBack}`);
              }}
              onViewCashier={() => {
                setShowModal((prev) => {
                  return { ...prev, view_cashier: true };
                });
              }}
              onRequestTerminal={() => {
                navigate("/dashboard-pos-management-request-pos-new");
              }}
              onGenerate={() => {
                setShowModal((prev) => {
                  return { ...prev, statement: true };
                });
              }}
              // viewPosTransaction={
              //   user_resource_and_permissions?.allowed_permissions?.includes(
              //     "can_view_terminal_transactions"
              //   ) && `View Transactions`
              // }
              settingPos={cashiers}
              onSettlementPos={() => {
                setShowModal((prev) => {
                  return { ...prev, settle: true };
                });
              }}
              onCashierDelete={(obj) => {
                setSingleChild(obj);
                setShowModal((prev) => {
                  return { ...prev, delete_cashier: true };
                });
              }}
              onAddCashier={() => {
                setShowModal((prev) => {
                  return { ...prev, assign_cashier: true };
                });
              }}
              onPosTopUp={() => {
                setShowModal((prev) => {
                  return { ...prev, top_up: true };
                });
              }}
            />
            {/* title box end */}
            {/* top detail card box start */}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("close");
                // console.log(terminalBusinessInfo);
              }}
              className="top-pos-single-detail-card-box-new"
            >
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Wallet Balance{" "}
                  <div
                    onClick={() => {
                      navigate("/dashboard-pos-management-withdraw");
                    }}
                    className="bottom-text-action"
                  >
                    <p className="text">Withdraw Balance</p>
                    <figure className="icon">{purpleNavIcon}</figure>
                    {/* <FaAngleRight className="icon" /> */}
                  </div>
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p className="value black-white-color">
                      {RavenNumberFormat(
                        terminalBusinessInfo?.account_details?.balance || "0.00"
                      )}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Cash In {filterVal?.label}{" "}
                  {/* <div className="title-total black-fade-white-bg">
                    <span>
                      {invoices?.total_paid_invoice || "0"}
                    </span>
                  </div> */}
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#1ACE37" }} className="value">
                      {RavenNumberFormat(
                        terminalBusinessInfo?.bank_box_profile?.data?.overiew
                          ?.cash_in_today || "0.00"
                      )}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Cash Out {filterVal?.label}
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#FF0F00" }} className="value">
                      {RavenNumberFormat(
                        terminalBusinessInfo?.bank_box_profile?.data?.overiew
                          ?.cash_out_today || "0.00"
                      )}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
            </div>
            {/* top detail card box send */}

            {/* bottom content box start ------------- */}
            <ContainerWrapper
              pagination={
                selectedView === tabSelect[1] &&
                Number(terminalTransactionAll?.pagination?.total) >
                  actionCtx?.perPageVal?.value &&
                !loading_trans && (
                  <RavenPagination
                    color={`black-light`}
                    nextPage={terminalTransactionAll?.pagination?.nextPage}
                    prevPage={terminalTransactionAll?.pagination?.prevPage}
                    blackHover
                    onNext={(e) => {
                      setCurrentPage(
                        terminalTransactionAll?.pagination?.currentPage + 1
                      );
                      // console.log(currentPage + 1);
                    }}
                    onPrev={(e) => {
                      setCurrentPage(
                        terminalTransactionAll?.pagination?.currentPage - 1
                      );
                      // console.log(currentPage - 1);
                    }}
                    currentPage={
                      terminalTransactionAll?.pagination?.currentPage
                    }
                    totalPage={
                      getTotalPage(
                        terminalTransactionAll?.pagination?.perPage,
                        terminalTransactionAll?.pagination?.total
                      ) || 1
                    }
                    onNumView={(e) => {
                      // setCurrentPage(e);
                      if (e !== "...") {
                        setCurrentPage(e);
                        // console.log(e);
                      }
                    }}
                  />
                )
              }
              width={`100%`}
              dontShowHeader
            >
              {/* pos inde wrap start */}
              <div
                onClick={() => {
                  // console.log(terminals);
                  // console.log(status);
                }}
                className="dashboard-pos-index-wrap"
              >
                {pending_terminals.length > 0 || terminals?.length > 0 ? (
                  <>
                    {" "}
                    <div
                      onClick={() => {
                        actionCtx?.setCheckShow("close");
                      }}
                      className="bottom-content-box-pos-index-new"
                    >
                      {/* all terminal , transaction and filter box start */}
                      <div className="all_terminal_transcation_filter-box border-theme-bottom">
                        {/* select box start */}
                        <div className="all-select-box">
                          {/* select view wrap start */}
                          <div className="select-view-wrap active-border-color">
                            {tabSelect.map((chi, idx) => {
                              return (
                                <p
                                  className={`option ${
                                    chi === selectedView &&
                                    "option-active black-white-color"
                                  }`}
                                  key={idx}
                                  onClick={() => {
                                    selectedView !== chi &&
                                      setSelectedView(chi);
                                  }}
                                >
                                  <span className="grey-white-color">
                                    {chi === "Terminals"
                                      ? `Terminals ( ${terminals?.length} )`
                                      : `All Transactions`}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                          {/* select view wrap end */}
                        </div>
                        {/* select box end */}
                        {/* filter search div start */}
                        {selectedView === tabSelect[1] && (
                          <div className="search-filter-box-pos">
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                              autoComplete="off"
                              action=""
                            >
                              <div className="search-group">
                                <RavenInputField
                                  type={`search`}
                                  color={`black-light`}
                                  placeholder={`Search`}
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                    // showFilterOption && setShowFilterOption(false);
                                  }}
                                />
                              </div>
                            </form>
                            {selectedView === tabSelect[1] ? (
                              <>
                                {/* select start */}
                                <form autoComplete="off" action="">
                                  <RavenInputField
                                    className="pos-select-wrap"
                                    type={`select`}
                                    value={
                                      filterVal || {
                                        label: "Today",
                                        value: "Today",
                                      }
                                    }
                                    // selectMenuOpen
                                    selectStyles={reactSelectStyle}
                                    color={"black-light"}
                                    selectOption={filterOptions}
                                    onChange={(e) => {
                                      if (
                                        e.label !== "Specific Date" &&
                                        e.label !== "Custom"
                                      ) {
                                        setFilterVal(e);
                                      }
                                      if (e.label === "Specific Date") {
                                        setShowModal((prev) => {
                                          return {
                                            ...prev,
                                            selectSpecificDate: true,
                                          };
                                        });
                                      }
                                      if (e.label === "Custom") {
                                        setShowModal((prev) => {
                                          return { ...prev, customDate: true };
                                        });
                                      }
                                      // handleFilterDasboardInfo(e.value);
                                    }}
                                  />
                                </form>
                                {/* select nd */}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                        {/* filter search div end */}
                      </div>

                      {/* real down content start, table or cards */}
                      {selectedView === tabSelect[0] ? (
                        <>
                          {/* all terminals for pending and active start */}
                          <div
                            style={{
                              gridTemplateColumns:
                                pending_terminals?.length + terminals?.length <
                                4
                                  ? `repeat(${
                                      pending_terminals?.length +
                                      terminals?.length
                                    }, 35rem)`
                                  : "",
                            }}
                            className={`all-pending-active-terminals-box-wrapper`}
                            onClick={() => {
                              console.log(terminals);
                            }}
                          >
                            {/* all terminal start here */}
                            {terminals?.map((chi, idx) => {
                              return (
                                <div
                                  key={`${chi?.id}-${idx}`}
                                  className={`terminal-card border-theme shadow-shadow-box-shadow`}
                                  onClick={() => {
                                    localStorage?.setItem(
                                      "ter_serial_no",
                                      chi?.serial
                                    );
                                    // localStorage?.setItem("ter_tid", chi?.tid)
                                    navigate(
                                      "/dashboard-pos-terminal-information-single"
                                    );
                                  }}
                                >
                                  {/* icon label serial box start */}
                                  <div className="icon-label-serial-box">
                                    <div className="icon-wrap grey-bg">
                                      <figure
                                        className={`img-box ${
                                          chi?.type === "virtual"
                                            ? "img-box-virtual"
                                            : ""
                                        }`}
                                      >
                                        {chi?.type === "virtual"
                                          ? virtualTerminalIcon
                                          : POS_ICON?.pos()}
                                      </figure>
                                    </div>
                                    {/* label serial box start */}
                                    <div className="label-serial-box">
                                      <p className="label">
                                        {trimLongString(chi?.tid_label, 20)}
                                      </p>
                                      {chi?.type === "virtual" ? (
                                        <>
                                          <BadgeComponent
                                            className={`terminal-badge`}
                                            text={`Virtual terminal`}
                                          >
                                            <figure className="img-box">
                                              {virtualIcon}
                                            </figure>
                                          </BadgeComponent>
                                        </>
                                      ) : (
                                        <p className="serial grey-white-color">
                                          serial: {chi?.serial || ""}
                                        </p>
                                      )}
                                    </div>
                                    {/* label serial box end */}
                                  </div>
                                  {/* icon label serial box end */}

                                  <div className="cash-in-box-wrap grey-bg">
                                    <p className="label grey-white-color">
                                      cash In
                                    </p>
                                    <p className="value">
                                      {RavenNumberFormat(chi?.cash_in)}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                            {/* all terminals end here */}
                            {/* pending start here  */}
                            {pending_terminals?.map((chi, idx) => {
                              return (
                                <div
                                  key={`${chi?.id}-${idx}`}
                                  className={`terminal-card border-theme shadow-shadow-box-shadow terminal-card-pending`}
                                >
                                  <div className="pending-tractk-box">
                                    <BadgeComponent
                                      onClick={() => {
                                        // console.log(pending_terminals);
                                        setSingleChild(chi);
                                        setShowModal((prev) => {
                                          return { ...prev, view_track: true };
                                        });
                                      }}
                                      className={`terminal-badge`}
                                      text={`Track Order Status`}
                                    >
                                      <figure className="img-box">
                                        {pendingIcon}
                                      </figure>
                                    </BadgeComponent>
                                  </div>

                                  {/* icon label serial box start */}
                                  <div className="icon-label-serial-box">
                                    <div className="icon-wrap grey-bg">
                                      <figure className="img-box">
                                        {POS_ICON?.pos()}
                                      </figure>
                                    </div>
                                    {/* label serial box start */}
                                    <div className="label-serial-box">
                                      <p className="label">
                                        {" "}
                                        {trimLongString(
                                          business?.business_name,
                                          23
                                        )}{" "}
                                        {`( ${chi?.quantity || ""} )`}
                                      </p>
                                      <p className="serial grey-white-color">
                                        Status: Pending
                                      </p>
                                    </div>
                                    {/* label serial box end */}
                                  </div>
                                  {/* icon label serial box end */}

                                  <div className="cash-in-box-wrap grey-bg">
                                    <p className="label grey-white-color">
                                      cash In
                                    </p>
                                    <p className="value">N816,300.00</p>
                                  </div>
                                </div>
                              );
                            })}
                            {/* pending start end */}
                          </div>
                          {/* all terminals for pending and active end */}
                        </>
                      ) : (
                        <>
                          <div className="table-content-pos">
                            {/* table contet end */}
                            {terminalTransactionAll?.transactions?.length >
                            0 ? (
                              <>
                                <RavenTable
                                  className={
                                    formatMetaData(user?.theme_display_settings)
                                      ?.table_font === "bold" &&
                                    "table-business"
                                  }
                                  action
                                  headerList={headerList}
                                >
                                  {terminalTransactionAll?.transactions?.map(
                                    (chi, idx) => {
                                      const {
                                        amount,
                                        created_at,
                                        direction,
                                        type,
                                        t_id,
                                        status,
                                        narration,
                                        meta_data,
                                      } = chi;
                                      return (
                                        <RavenTableRow
                                          loading={loading_trans}
                                          key={idx}
                                          one={
                                            rowTypeText(
                                              direction,
                                              type === "card"
                                                ? "Card Payment"
                                                : chi?.type ===
                                                    "pdon_card_raven" ||
                                                  chi?.type === "pdon_card"
                                                ? `BankBox Payment`
                                                : type === "transfer_out"
                                                ? formatMetaData(meta_data)
                                                    ?.account_name ||
                                                  trimLongString(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )
                                                      ?.business_destination_account
                                                      ?.account_name,
                                                    30
                                                  ) ||
                                                  "Transfer out"
                                                : type === "transfer_out - RVSL"
                                                ? "Transfer Out Reversal"
                                                : type ===
                                                  "transfer_out fee - RVSL"
                                                ? "Transfer Out Reversal Fee"
                                                : type === "transfer_in"
                                                ? formatMetaData(meta_data)
                                                    ?.source?.first_name ||
                                                  trimLongString(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.business_banking_account
                                                      ?.account_name,
                                                    30
                                                  ) ||
                                                  "Transfer in"
                                                : type === "bet"
                                                ? `Bet Recharge`
                                                : type === "electricity"
                                                ? `Electricity Recharge`
                                                : type === "pos_cautionfee_rvsl"
                                                ? "Caution Fee reversal"
                                                : narration?.top_narration ||
                                                  capitalizeFirstLetter(
                                                    formatTypeFunction(type)
                                                  ) ||
                                                  "----",
                                              type === "card"
                                                ? formatNUmPan(
                                                    formatMetaData(meta_data)
                                                      ?.card_pan
                                                  )
                                                : chi?.type ===
                                                  "pdon_card_raven"
                                                ? formatNUmPan(
                                                    formatMetaData(meta_data)
                                                      ?.card_pan
                                                  )
                                                : type === "transfer_out"
                                                ? `${
                                                    formatMetaData(meta_data)
                                                      ?.account_number ||
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )
                                                      ?.business_destination_account
                                                      ?.account_number ||
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.narration ||
                                                    ""
                                                  } • ${
                                                    formatMetaData(meta_data)
                                                      ?.bank ||
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )
                                                      ?.business_destination_account
                                                      ?.bank ||
                                                    ""
                                                  }`
                                                : type === "transfer_out - RVSL"
                                                ? "system reversed"
                                                : type === "pos_cautionfee_rvsl"
                                                ? "system reversed"
                                                : type === "transfer_in"
                                                ? `${
                                                    formatMetaData(meta_data)
                                                      ?.source
                                                      ?.account_number ||
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.business_banking_account
                                                      ?.account_number
                                                  } • ${trimLongString(
                                                    formatMetaData(meta_data)
                                                      ?.source?.bank ||
                                                      formatMetaData(
                                                        chi?.meta_data
                                                      )
                                                        ?.business_banking_account
                                                        ?.bank,
                                                    16
                                                  )}`
                                                : type === "bet"
                                                ? `${
                                                    formatMetaData(meta_data)
                                                      ?.type
                                                  } • ${trimLongString(
                                                    formatMetaData(meta_data)
                                                      ?.name,
                                                    16
                                                  )}`
                                                : type === "cable"
                                                ? `${capitalizeFirstLetter(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.provider
                                                  )} • ${
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.smart_card_number
                                                  }`
                                                : type === "airtime"
                                                ? `${capitalizeFirstLetter(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.network || ""
                                                  )} • ${
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.phone_number
                                                  }`
                                                : type === "data"
                                                ? `${capitalizeFirstLetter(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.network || ""
                                                  )} • ${
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.phone_number
                                                  }`
                                                : type === "electricity"
                                                ? `${
                                                    formatMetaData(meta_data)
                                                      ?.meta?.service ||
                                                    formatMetaData(meta_data)
                                                      ?.provider ||
                                                    "---"
                                                  } • ${
                                                    formatMetaData(meta_data)
                                                      ?.meta?.meter_number ||
                                                    formatMetaData(meta_data)
                                                      ?.meter_no ||
                                                    "---"
                                                  }`
                                                : type === "airtime"
                                                ? formatMetaData(meta_data)
                                                    ?.phone_number
                                                : type ===
                                                  "transfer_out fee - RVSL"
                                                ? "system reversed"
                                                : narration?.bottom_narration ||
                                                  "----",
                                              chi
                                            ) || "----"
                                          }
                                          four={
                                            formatDate(created_at) || "----"
                                          }
                                          three={t_id || "---"}
                                          two={
                                            formatNumWithCommaNairaSymbol(
                                              amount || "0.00"
                                            ) || "----"
                                          }
                                          onRowClick={() => {
                                            setSingleObj(chi);
                                            setShowModal((prev) => {
                                              return { ...prev, view: true };
                                            });
                                            // console.log(chi);
                                            // console.log(
                                            //   formatMetaData(chi?.meta_data)
                                            // );
                                          }}
                                          five={formatTypeWrap(status, chi)}
                                          action
                                          dontShowAction
                                          dontShowEdit
                                          ManualAddActions={() =>
                                            transactionActions(chi)
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </RavenTable>
                                <div className="mobile-table-box">
                                  {terminalTransactionAll?.transactions?.map(
                                    (chi, idx) => {
                                      const {
                                        amount,
                                        created_at,
                                        direction,
                                        type,
                                      } = chi;
                                      return (
                                        <MobileTableCard
                                          key={idx}
                                          amount={formatNumWithCommaNairaSymbol(
                                            amount
                                          )}
                                          text={`${
                                            formatDate(created_at).split(
                                              "—"
                                            )[0] || ""
                                          } • ${type}`}
                                          onRowClick={() => {
                                            setSingleObj(chi);
                                            setShowModal((prev) => {
                                              return { ...prev, view: true };
                                            });
                                            //   console.log(chi);
                                          }}
                                          loading={loading}
                                          title={type}
                                          showImg
                                          type={direction}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            ) : (
                              <NoContentBox
                                title={
                                  sorceFilter
                                    ? `No Transaction Found for terminal ${sorceFilter?.label}`
                                    : `No Transaction Found`
                                }
                                loading={loading_trans}
                              />
                            )}
                            {/* table end */}
                          </div>
                        </>
                      )}
                      {/* real down bottom content end, table or cards */}

                      {/* terminals show box end */}
                    </div>
                  </>
                ) : (
                  <NoContentBox
                    loading={loading || loading_trans}
                    title={`No Bankbox yet`}
                    text={`Click the button below to request a Bankbox`}
                    btnText={`Request BankBox`}
                  />
                )}
              </div>
              {/* pos inde wrap end */}
            </ContainerWrapper>
            {/* bottom content box end ------------- */}
          </>
        ) : (
          <NocontentPage
            title={`Scale your business with a BankBox.🚀`}
            list={noContentList}
            img={noContentImg}
            loading={loading}
            btntext={
              formatStringPermissionToLowerCase(
                user_resource_and_permissions?.allowed_permissions
              )?.includes("can_request_bankbox") && `Request for a BankBox`
            }
            onBtnClick={() => {
              // navigate("/dashboard-pos-management-request-pos");
              navigate("/dashboard-pos-management-request-pos-new");
            }}
          />
        )}
      </DashboardLayout>
      {/* cashier modal start */}
      <AssignCashierModal
        visible={showModal?.assign_cashier}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, assign_cashier: false };
          });
        }}
        onFinish={(obj) => {
          setDetails((prev) => {
            return { ...prev, ...obj };
          });
          setShowModal((prev) => {
            return { ...prev, pin: true, assign_cashier: false };
          });
        }}
      />
      {/* cashier modal end */}
      {/* delete cashier modal start */}
      <DeleteCashierModal
        visible={showModal?.delete_cashier}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_cashier: false };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* delete cashier modal end */}
      {/* delete terminal modal start */}
      <DeleteTerminalModal
        visible={showModal?.delete_terminal}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_terminal: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* delete terminal modal start */}
      {/* <TransactionDetailModal
        visible={showModal?.view}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        detail={singleChild}
      /> */}
      {/* delete terminal modal end */}
      {/* delete terminal modal start */}
      <ViewCashierModal
        visible={showModal?.view_cashier}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view_cashier: false };
          });
        }}
        onCashierDelete={(obj) => {
          setSingleChild(obj);
          setShowModal((prev) => {
            return { ...prev, delete_cashier: true, view_cashier: false };
          });
        }}
        settingPos={cashiers}
        onAdd={() => {
          setShowModal((prev) => {
            return { ...prev, assign_cashier: true, view_cashier: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* delete terminal modal start */}
      <ViewTopUPModal
        visible={showModal?.top_up}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, top_up: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* genrate start */}
      <GenerateStatementModal
        visible={showModal?.statement}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, statement: false };
          });
        }}
      />
      {/* genrate end */}
      {/* specific date start  */}
      <SpecificDateSelectModal
        visible={showModal?.selectSpecificDate}
        onFinish={(e) => {
          // console.log(e);
          setFilterVal({
            label: formatDate(e)?.split("—")[0],
            value: e,
            name: "Specific Date",
          });
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
      />
      {/* specific date end */}
      {/* settlement settings modal start */}
      <SettlementSettingModal
        visible={showModal?.settle}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, settle: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* settlement settings modal end */}
      {/* Custom date start */}
      <CustomDateSelectModal
        visible={showModal?.customDate}
        onFinish={(e) => {
          formatFilterDate(e);
          setFilterVal({
            label: formatFilterDate(e),
            value: e,
            name: "Custom Date",
          });
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
      />
      {/* custom date end */}
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, pin: e };
          });
        }}
        onPay={(pin, token) => {
          handleSubmitDetails(pin, token);
        }}
        value={details?.pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false, assign_cashier: true };
          });
        }}
        loading={loadingSettlement}
      />
      <DownloadReceiptModal
        visible={showModal?.view}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, view: false };
          })
        }
        detail={singleObj}
      />
      {/* pin modal end */}
      <TrackDeliveryModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view_track: false };
          });
        }}
        visible={showModal?.view_track}
        details={singleChild}
      />

      <BlurWrap />
    </>
  );
};

export default DashboardPosIndexNew;
