import {
  RavenModal,
  RavenNumberFormat,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadTransactionReciept } from "../../../redux/transaction";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
// import copyIcon from "../../../assets/side-copy-icon.svg";
import {
  convertToLowercase,
  // formatDate,
  // formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
import { FaCheck } from "react-icons/fa";
import { useDarkMode } from "../../../utils/themes/useDarkMode";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";
import CheckUrlFragment from "../../../components/fragments/CheckUrlCodeFragment";

const DownloadReceiptModal = ({ onClose, visible, param, detail }) => {
  const { loadingDownload } = useSelector((state) => state.transaction);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const dispatch = useDispatch();
  const formatMetaData = (param) => {
    if (param) {
      const obj = JSON?.parse(param);
      // console.log(obj);
      return obj;
    }
  };

  const handleSubmit = async () => {
    const obj = {
      transaction_id: String(detail?.id),
    };
    const data = await dispatch(downloadTransactionReciept(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const swapIcon = (
    <svg
      className="img"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77778 2.55566V11.4446M9.77778 11.4446L7.55556 9.22233M9.77778 11.4446L12 9.22233M4.22222 11.4446V2.55566M4.22222 2.55566L2 4.77789M4.22222 2.55566L6.44444 4.77789"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const [isCopied, setIsCopied] = useState("");

  const handleCopied = async (param, copy) => {
    setIsCopied(copy);
    await navigator.clipboard.writeText(param);
    setTimeout(() => {
      setIsCopied("");
    }, 1000);
  };

  const newCopyIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <RavenModal
      btnColor={`black-light`}
      visble={visible}
      btnLabel={
        user_resource_and_permissions?.allowed_permissions?.includes(
          "can_download_transaction_receipts"
        ) && `Download Reciept`
      }
      className="transaction-download-receipt-modal"
      onClose={onClose}
      onBtnClick={handleSubmit}
      loading={loadingDownload}
      // dontShowClose={true}
    >
      <div className="wrap-modal">
        <p
          onClick={() => {
            // console.log(formatMetaData(detail?.meta_data));
            // console.log(detail);
          }}
          className="title"
        >
          Transaction Reciept
        </p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          {formatTypeFunction(detail?.type) === "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">Recipient</p>
              <p className="value-download">{`System Reversal`}</p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Amount:</p>
            <p className="value-download">
              {/* {formatNumWithCommaNairaSymbol(detail?.amount || 0)} */}
              {RavenNumberFormat(detail?.amount, { symbol: detail?.currency })}
            </p>
          </div>
          {/* box end */}
          {detail?.currency && detail?.type === "swap" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">
                  Swap Currencies:
                </p>
                <div className="value-download value-download value-download-two">
                  <div className="avatar-box">
                    {" "}
                    <CheckUrlFragment
                      url={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                        formatMetaData(detail?.meta_data)?.from
                      )}.png`}
                    ></CheckUrlFragment>
                  </div>

                  {formatMetaData(detail?.meta_data)?.from || "---"}
                  <figure
                    style={{ transform: "rotate(90deg)" }}
                    className="img-box tooltip-hover-wrap"
                  >
                    {swapIcon}
                  </figure>
                  <div className="avatar-box">
                    {" "}
                    <CheckUrlFragment
                      url={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                        formatMetaData(detail?.meta_data)?.to
                      )}.png`}
                    ></CheckUrlFragment>
                  </div>

                  {formatMetaData(detail?.meta_data)?.to || "---"}
                </div>
              </div>
            </>
          )}
          {/* box start */}
          {/* {detail?.fee  && ( */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction Fee:</p>
            <p className="value-download">
              {RavenNumberFormat(detail?.fee || 0)}
            </p>
          </div>
          {/* )} */}
          {/* box end */}
          {/* box start */}
          {Number(detail?.amount) >= 10000 ?
            formatTypeFunction(detail?.type) === "Money received" && (
              <div className="box">
                <p className="label-download grey-white-color">Stamp Duty</p>
                <p style={{ width: "80%" }} className="value-download">
                  {RavenNumberFormat(detail?.stamp_duty || "0")}
                </p>
              </div>
            ) : ""}
          {/* box end */}
          {/* {detail?.fee  && ( */}
          {detail?.bal_before ? (
            <div className="box">
              <p className="label-download grey-white-color">Balance before:</p>
              <p className="value-download">
                {RavenNumberFormat(detail?.bal_before || 0)}
              </p>
            </div>
          ) : ""}
             {detail?.bal_after ? (
            <div className="box">
              <p className="label-download grey-white-color">Balance after:</p>
              <p className="value-download">
                {RavenNumberFormat(detail?.bal_after || 0)}
              </p>
            </div>
          ) : ""}
          {/* )} */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Airtime recharge" ||
          formatTypeFunction(detail?.type) === "Data Recharge" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {lowerCaseWrap(formatMetaData(detail?.meta_data)?.network) ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Phone number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.phone_number || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Electricity payment" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Plan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.meter_type || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meter_no || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Token</p>
                <p className="value-download">
                  {detail?.electricity_token || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Cable recharge" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.smart_card_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {formatTypeFunction(detail?.type) === "Money received" &&
            formatMetaData(detail?.meta_data)?.first_name &&
            formatMetaData(detail?.meta_data)?.last_name && (
              <>
                <div className="box">
                  <p className="label-download grey-white-color">Sent By:</p>
                  <p className="value-download">
                    {`${
                      formatMetaData(detail?.meta_data)?.first_name || "---"
                    } ${formatMetaData(detail?.meta_data)?.last_name || "---"}`}
                  </p>
                </div>
                {/* box end electricity_token*/}
              </>
            )}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Money received" ||
          formatTypeFunction(detail?.type) === "Money sent" ||
          formatTypeFunction(detail?.type) === "Reversal" ||
          formatTypeFunction(detail?.type) === "Transaction charge" ? (
            <>
              {/* box start */}
              {!formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Bank:</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.bank || "---"}
                  </p>
                </div>
              )}
              {formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Bank:</p>
                  <p className="value-download">Raven Bank</p>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {!formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Account No.</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.account_number || "---"}
                  </p>
                </div>
              )}
              {formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">
                    Raven Username
                  </p>
                  <p className="value-download">
                    {`@${formatMetaData(detail?.meta_data)?.identifier}` ||
                      "---"}
                  </p>
                </div>
              )}
              {/* box end */}
              {formatTypeFunction(detail?.type) === "Money sent" && (
                <>
                  {" "}
                  {/* box start */}
                  <div className="box">
                    <p className="label-download grey-white-color">Recipient</p>
                    <p className="value-download">
                      {formatMetaData(detail?.meta_data)?.account_name || "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}

              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}

          {/* box start */}
          {formatTypeFunction(detail?.type) !== "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">
                Transaction Type{" "}
              </p>
              <p className="value-download">
                {detail?.type === "invoice-payment"
                  ? "Invoice Payment"
                  : detail?.type === "payment-link"
                  ? "Payment Link"
                  : formatTypeFunction(detail?.type) === "Money sent"
                  ? "Debit"
                  : formatTypeFunction(detail?.type) === "Money received"
                  ? "Credit"
                  : formatTypeFunction(detail?.type) || "---"}
              </p>
            </div>
          )}
          {/* box end */}
          {detail?.type === "payment-link" && (
            <>
              {" "}
              <div className="box">
                <p className="label-download grey-white-color">
                  Customer Email
                </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_email || ""}
                </p>
              </div>
            </>
          )}
          {formatTypeFunction(detail?.type) === "Payroll charge" && (
            <>
              {" "}
              <div className="box">
                <p className="label-download grey-white-color">Narration: </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.narration}
                </p>
              </div>
            </>
          )}
          {detail?.type === "invoice-payment" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Invoice Id </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.narration?.replace(
                    "payment for invoice",
                    ""
                  )}
                </p>
              </div>
              <div className="box">
                <p className="label-download grey-white-color">
                  Customer Email
                </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_email || ""}
                </p>
              </div>
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction date </p>
            <p className="value-download">
              {/* {formatDate(detail?.created_at) || "---"} */}
              <DateFragmentBox
                date={detail?.created_at}
                // dontShowTime={true}
              />
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          {detail?.session_id ||
          formatMetaData(detail?.meta_data)?.session_id ? (
            <div className="box">
              <p className="label-download grey-white-color">Session ID</p>
              <div
                // style={{ width: "80%" }}
                className="value-download value-download-two tooltip-hover-wrap"
              >
                <p>
                  {" "}
                  {detail?.session_id ||
                    trimLongString(
                      formatMetaData(detail?.meta_data)?.session_id,
                      18
                    ) ||
                    "---"}
                </p>
                {formatMetaData(detail?.meta_data)?.session_id?.length > 18 && (
                  <RavenToolTip
                    color="black-light"
                    position={`top-center`}
                    text={
                      formatMetaData(detail?.meta_data)?.session_id ||
                      "------------"
                    }
                  />
                )}

                {isCopied === "session" ? (
                  <div
                    style={{
                      backgroundColor:
                        theme === "light" ? "#020202" : "#ffffff",
                    }}
                    className="copied-box-reciept "
                  >
                    <FaCheck
                      style={{ fill: theme === "dark" ? "#020202" : "#ffffff" }}
                      className="icon"
                    />
                  </div>
                ) : (
                  <figure
                    onClick={() =>
                      handleCopied(
                        formatMetaData(detail?.meta_data)?.session_id,
                        "session"
                      )
                    }
                    className="img-box-reciept"
                    style={{
                      backgroundColor:
                        theme === "light"
                          ? "#f9f9f9"
                          : "rgba(249, 249, 249, .3)",
                    }}
                  >
                    {newCopyIcon}
                  </figure>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* box end */}
          {formatTypeFunction(detail?.type) !== "Reversal" && (
            <>
              {" "}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.reference ||
              detail?.merchant_ref ? (
                <div className="box">
                  <p className="label-download grey-white-color">
                    Transaction Ref.
                  </p>
                  <div className="value-download value-download-two ref">
                    <p>
                      {" "}
                      {formatMetaData(detail?.meta_data)?.reference ||
                        detail?.merchant_ref ||
                        "---"}
                    </p>
                    {isCopied === "ref" ? (
                      <div
                        style={{
                          backgroundColor:
                            theme === "light" ? "#020202" : "#ffffff",
                        }}
                        className="copied-box-reciept "
                      >
                        <FaCheck
                          style={{
                            fill: theme === "dark" ? "#020202" : "#ffffff",
                          }}
                          className="icon"
                        />
                      </div>
                    ) : (
                      <figure
                        onClick={() =>
                          handleCopied(
                            formatMetaData(detail?.meta_data)?.reference ||
                              detail?.merchant_ref,
                            "ref"
                          )
                        }
                        className="img-box-reciept"
                        style={{
                          backgroundColor:
                            theme === "light"
                              ? "#f9f9f9"
                              : "rgba(249, 249, 249, .3)",
                        }}
                      >
                        {newCopyIcon}
                      </figure>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* box end */}
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Status </p>
            <div
              className={`status-type-wrap ${
                detail?.status === "0" && "status-type-wrap-pending"
              } ${detail?.status === "3" && "status-type-wrap-success"} ${
                detail?.status === "11" && "status-type-wrap-success"
              } ${detail?.status === "success" && "status-type-wrap-success"} ${
                detail?.status === "paid" && "status-type-wrap-success"
              } ${detail?.status === "2" && "status-type-wrap-fail"} ${
                detail?.status === "overdue" && "status-type-wrap-pending"
              } ${detail?.status === "1" && "status-type-wrap-pending"} ${
                detail?.status === "pending" && "status-type-wrap-pending"
              }`}
            >
              <span>
                {" "}
                {detail?.status === "1"
                  ? "Processing"
                  : detail?.status === "0"
                  ? "Pending"
                  : detail?.status === "3"
                  ? "Success"
                  : detail?.status === "2"
                  ? "Failed"
                  : detail?.status === "11"
                  ? "Success"
                  : detail?.status}
              </span>
            </div>
          </div>
          {/* box end */}
        </div>
        {/* label-value-status box end */}
      </div>
    </RavenModal>
  );
};

export default DownloadReceiptModal;
