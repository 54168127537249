const { Encoder: OgEncoder, Decoder: OgDecoder, PacketType } = require("socket.io-parser");
const { reconstructPacket } = require("socket.io-parser/dist/binary");
const { isBinary } = require("socket.io-parser/dist/is-binary");

class Encoder extends OgEncoder {
  encode(obj) {
    // debugger;
    obj.nsp = ["/v1"].includes(obj.nsp) ? "/" : obj.nsp;
    return super.encode(obj);
  }
}

class Decoder extends OgDecoder {
  constructor() {
    super();
    this.customReconstructor = null;
  }

  add(obj) {
    let packet;

    if (typeof obj === "string") {
      if (this.customReconstructor) {
        throw new Error("Got plaintext data when reconstructing a packet");
      }

      packet = this.decodeStringNew(obj);

      if (packet.type === PacketType.BINARY_EVENT || packet.type === PacketType.BINARY_ACK) {
        this.customReconstructor = new BinaryReconstructor(packet);

        if (packet.attachments === 0) {
          super.emit("decoded", packet);
        }
      } else {
        super.emit("decoded", packet);
      }
    } else if (isBinary(obj) || obj.base64) {
      if (!this.customReconstructor) {
        throw new Error("Got binary data when not reconstructing a packet");
      } else {
        packet = this.customReconstructor.takeBinaryData(obj);
        if (packet) {
          this.customReconstructor = null;
          super.emit("decoded", packet);
        }
      }
    } else {
      throw new Error("Unknown type: " + obj);
    }
  }

  decodeStringNew(str) {
    let i = 0;
    const p = { type: Number(str.charAt(0)) };

    if (PacketType[p.type] === undefined) {
      throw new Error("Unknown packet type " + p.type);
    }

    if (p.type === PacketType.BINARY_EVENT || p.type === PacketType.BINARY_ACK) {
      const start = i + 1;
      while (str.charAt(++i) !== "-" && i !== str.length) {}
      const buf = str.substring(start, i);

      if (buf != Number(buf) || str.charAt(i) !== "-") {
        throw new Error("Illegal attachments");
      }
      p.attachments = Number(buf);
    }

    if (str.charAt(i + 1) === "/") {
      const start = i + 1;
      while (++i) {
        const c = str.charAt(i);
        if (c === "," || i === str.length) break;
      }
      p.nsp = str.substring(start, i);
    } else {
      p.nsp = "/v1";
    }

    const next = str.charAt(i + 1);
    if (next !== "" && Number(next) == next) {
      const start = i + 1;
      while (++i) {
        const c = str.charAt(i);
        if (c == null || Number(c) != c) {
          --i;
          break;
        }
        if (i === str.length) break;
      }
      p.id = Number(str.substring(start, i + 1));
    }

    if (str.charAt(++i)) {
      const payload = tryParse(str.substr(i));
      if (Decoder.isPayloadValid(p.type, payload)) {
        p.data = payload;
      } else {
        throw new Error("Invalid payload");
      }
    }

    return p;
  }

  static isPayloadValid(type, payload) {
    switch (type) {
      case PacketType.CONNECT:
        return typeof payload === "object";
      case PacketType.DISCONNECT:
        return payload === undefined;
      case PacketType.CONNECT_ERROR:
        return typeof payload === "string" || typeof payload === "object";
      case PacketType.EVENT:
      case PacketType.BINARY_EVENT:
        return Array.isArray(payload) && payload.length > 0;
      case PacketType.ACK:
      case PacketType.BINARY_ACK:
        return Array.isArray(payload);
    }
  }
}

function tryParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

class BinaryReconstructor {
  constructor(packet) {
    this.reconPack = packet;
    this.buffers = [];
  }

  takeBinaryData(binData) {
    this.buffers.push(binData);
    if (this.buffers.length === this.reconPack?.attachments) {
      const packet = reconstructPacket(this.reconPack, this.buffers);
      this.finishedReconstruction();
      return packet;
    }
    return null;
  }

  finishedReconstruction() {
    this.reconPack = null;
    this.buffers = [];
  }
}

module.exports = { Encoder, Decoder };
